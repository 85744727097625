
  import { dispatchGetCheckoutSession } from "@/store/main/actions";
  import { readCheckoutSession } from "@/store/main/getters";
  import { Vue, Component } from "vue-property-decorator";

  @Component
  export default class CheckoutConfirm extends Vue {
    get checkoutSession() {
      return readCheckoutSession(this.$store);
    }

    public async mounted() {
      await dispatchGetCheckoutSession(this.$store, {
        checkoutSessionId: +this.$route.params.id,
      });
    }
  }
